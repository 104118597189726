// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 
  magazineSearchIndex: 'dev-magazinesearch-index',
  magazineSearchService: 'mfmagsearch',
  mfServerUrl: 'https://mf-magazine-api-dev.azurewebsites.net',
  loginUrl: 'https://mf-magazine-api-dev.azurewebsites.net/api/Account/',
  logoutUrl: 'https://mf-magazine-api-dev.azurewebsites.net/api/Account/Logout/',
  refreshTokenUrl: 'https://mf-magazine-api-dev.azurewebsites.net/api/Account/RefreshToken',
  azureSellerSearchUrl: "https://mfmagsearch.search.windows.net/indexes/dev-sellersearch-index/docs",
  azureBuyerSearchUrl: "https://mfmagsearch.search.windows.net/indexes/dev-buyersearch-index/docs",
  azureMagazineWithIconSearchUrl: "https://mfmagsearch.search.windows.net/indexes/dev-titlesearch-index/docs",
  ApplicationInsightsInstrumentationKey: '0caee203-c004-4213-a7f2-131bac8f3e50',
  azureSearchApiVersion: '2019-05-06',
  azureSearchApiKey: 'mm909r2mu99YxoqpZ0Povn2C79whvRexp2cuj2IvS2AzSeAD69lh',
  paymentSetupLink: 'https://sbcs.billerpayments.com/app/cust/login.do?bsn=mediafiche',
  //makePaymentLink:'https://sbcs.billerpayments.com/app/guestpayui/index.html?bsn=mediafiche'

  apiRootUrl: "https://mf-magazine-api-dev.azurewebsites.net",
  clientRootUrl: "https://magazine.dev.mediafiche.com",
  identityProviderRootUrl: "https://sso.dev.mediafiche.com",
  clientId: "mf-magazine-client-dev",
  scopes: "openid profile roles MagazineApi.FullAccess"
};